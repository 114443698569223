import { MouseEvent, MouseEventHandler, useEffect, useState } from 'react';

/**
 * Arranges for a button to be disabled while its async click action runs.
 *
 * The hook returns an async onClick and running properties
 * This hook should be called with  containing upto three
 * This hook should be called with onClick (mandatory) and delay time.
 *
 * @returns {Object}
 * @param {function} onClick the asynchronous button action (mandatory)
 * @param {number} [delay]     an optional delay (in ms). Used to delay the re-enbling of the button so that even
 * if the asynchronous action is quick it cannot be re-invoked for an additional period. This is handy for
 * throttling/debouncing functions.
 * */
export function useAsyncButton(
  onClick: MouseEventHandler<HTMLButtonElement | HTMLElement>,
  delay = 0,
  disableInitially = false
) {
  const [running, setRunning] = useState(disableInitially);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRunning(false);
    }, delay * 1000);

    return () => clearTimeout(timeout);
  }, []);

  return {
    onClick: async (e: MouseEvent<HTMLButtonElement | HTMLElement>) => {
      setRunning(true);
      try {
        await onClick(e);
        await new Promise((resolve) => setTimeout(resolve, delay * 1000));
      } finally {
        setRunning(false);
      }
    },
    running
  };
}

export default useAsyncButton;
