/* eslint-disable test-selectors/button */
import React, { useContext, useEffect, useState } from 'react';
import { Button, ButtonProps } from 'antd';
import useAsyncButton from '@hooks/useAsyncButton';

type CoreAsyncButtonProps = { delay?: number; disabled?: boolean; disableInitially?: boolean };

type AsyncAntdButtonProps = CoreAsyncButtonProps & ButtonProps & { htmlButton?: undefined };
type AsyncHtmlButtonProps = CoreAsyncButtonProps & React.HTMLAttributes<HTMLButtonElement> & { htmlButton: true };

type AsyncButtonComponent = {
  Button: typeof ButtonItem;
  TimeLeft: typeof TimeLeft;
  (props: AsyncAntdButtonProps): JSX.Element;
  (props: AsyncHtmlButtonProps): JSX.Element;
};

const isHtmlButton = (props: AsyncAntdButtonProps | AsyncHtmlButtonProps): props is AsyncHtmlButtonProps =>
  'htmlButton' in props;

const TimeLeft = ({ type }: { type: string }) => {
  const { disabledAsync, timeLeft } = useContext(AsyncButtonContext);
  return (
    <>
      {disabledAsync && timeLeft > -1 && (
        <>
          {type} sent. You can retry in {timeLeft}s
        </>
      )}
    </>
  );
};

const ButtonItem = ({ children }: { children: React.ReactNode }) => {
  const { htmlButton, onClickAsync, disabledAsync, disabled, props } = useContext(AsyncButtonContext);

  return (
    <div>
      {htmlButton ? (
        <button type="button" {...props} onClick={onClickAsync} disabled={disabledAsync || disabled}>
          {children}
        </button>
      ) : (
        <Button {...props} onClick={onClickAsync} disabled={disabledAsync || disabled}>
          {children}
        </Button>
      )}
    </div>
  );
};

const AsyncButtonContext = React.createContext({
  htmlButton: false,
  onClickAsync: (e: any) => {},
  disabledAsync: false,
  disabled: false,
  timeLeft: 0,
  props: {}
});

const AsyncButton: AsyncButtonComponent = ({
  onClick,
  disabled = false,
  delay = 0,
  children,
  disableInitially = false,
  ...props
}: AsyncAntdButtonProps | AsyncHtmlButtonProps) => {
  const { onClick: onClickAsync, running: disabledAsync } = useAsyncButton(
    onClick || (() => {}),
    delay,
    disableInitially
  );
  const [timeLeft, setTimeLeft] = useState(delay);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (disabledAsync) {
        setTimeLeft(timeLeft - 1);
      } else {
        setTimeLeft(delay);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [disabledAsync, timeLeft]);

  return (
    <AsyncButtonContext.Provider
      value={{
        onClickAsync,
        disabledAsync,
        disabled,
        timeLeft,
        htmlButton: isHtmlButton(props),
        props
      }}
    >
      {children}
    </AsyncButtonContext.Provider>
  );
};

AsyncButton.TimeLeft = TimeLeft;
AsyncButton.Button = ButtonItem;

export default AsyncButton;
